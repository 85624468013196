import { Inject, Injectable, InjectionToken } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env';
import { Work } from 'src/app/models/work';

export const CURRENT_PROVIDER = new InjectionToken('CURRENT_PROVIDER')

@Injectable()
export class WorkService {

  constructor(
    private http: HttpClient,
    @Inject(CURRENT_PROVIDER) public currentProvider
  ) {}

  getWork(id: string) {
    return this.http.get<any>(environment.apiUrl + 'getWork/' + id);
  }

  getWorks(department: any, codWork: string, startDate, finishDate, company: any,
    center: string, codCenter: any, affair: string, employees: any, status: any, serverSide = null) {

    const jsonData = {
      startDate: startDate,
      finishDate: finishDate,
      department: department,
      codWork: codWork,
      company: company,
      center: center,
      codCenter: codCenter,
      affair: affair,
      employees: employees,
      status: status,
      serverSide: serverSide,
      provider: this.currentProvider
    };

    const params = JSON.stringify(jsonData);
    return this.http.get<any>(environment.apiUrl + 'getWorks/' + params);
  }

  getWorksBilling(startDate, finishDate, company: any, provider) {

    const jsonData = {
      startDate: startDate,
      finishDate: finishDate,
      company: company,
      provider: provider
    };

    const params = JSON.stringify(jsonData);
    return this.http.get<any>(environment.apiUrl + 'getWorksBilling/' + params);
  }

  getWorksAfter15Days() {
    const jsonData = {
      provider: this.currentProvider
    };

    const params = JSON.stringify(jsonData);
    return this.http.get<any>(environment.apiUrl + 'getWorksAfter15Days/' + params);
  }

  getHistoryWorksElement(element: any) {

    const jsonData = {
      element: element,
      provider: this.currentProvider
    };

    const params = JSON.stringify(jsonData);
    return this.http.get<any>(environment.apiUrl + 'getHistoryWorksElement/' + params);
  }

  createWork(work: Work, date = null) {
    work.provider = this.currentProvider;
    if (date == null) {
      return this.http.post<any>(environment.apiUrl + 'createWork', work);
    } else {
      return this.http.post<any>(environment.apiUrl + 'createWork/' + date, work);
    }

  }

  updateWork(id: string, work: Work) {
    return this.http.put<any>(environment.apiUrl + 'updateWork/' + id, work);
  }

  deleteWork(id: string) {
    return this.http.delete<any>(environment.apiUrl + 'deleteWork/' + id);
  }

  getResumenPysecor (dates: any) {
    const jsonData = {
      dates,
      provider: this.currentProvider
    };

    let params = JSON.stringify(jsonData);
    return this.http.get<any>(environment.apiUrl + 'resumen-works/' + params);
  }

}
