export const consmareItems = [
  {
    type: 'separator',
    name: 'Empresas',
    link: '',
    route: '',
    icon: '',
    role: {
      ROLE_SADMIN: true,
      ROLE_ADMIN: true,
      ROLE_TECHNICAL: false,
      ROLE_CLIENT: false,
      ROLE_SUPERVISOR: false
    },
    permission: null
  },
  {
    type: 'link',
    name: 'Clientes',
    link: 'clientes',
    route: '/apps/consmare',
    icon: 'people',
    role: {
      ROLE_SADMIN: true,
      ROLE_ADMIN: true,
      ROLE_TECHNICAL: false,
      ROLE_CLIENT: false,
      ROLE_SUPERVISOR: false
    },
    permission: null
  },
  {
    type: 'separator',
    name: 'Personal',
    link: '',
    route: '',
    icon: '',
    role: {
      ROLE_SADMIN: true,
      ROLE_ADMIN: true,
      ROLE_TECHNICAL: false,
      ROLE_CLIENT: false,
      ROLE_SUPERVISOR: false
    },
    permission: null
  },
  {
    type: 'link',
    name: 'Empleados',
    link: 'personal',
    route: '/apps/consmare',
    icon: 'people',
    role: {
      ROLE_SADMIN: true,
      ROLE_ADMIN: true,
      ROLE_TECHNICAL: false,
      ROLE_CLIENT: false,
      ROLE_SUPERVISOR: false
    },
    permission: null
  },
  {
    type: 'separator',
    name: 'Partes de trabajo',
    link: '',
    route: '',
    icon: '',
    role: {
      ROLE_SADMIN: true,
      ROLE_ADMIN: true,
      ROLE_TECHNICAL: true,
      ROLE_CLIENT: true,
      ROLE_SUPERVISOR: true
    },
    permission: null
  },
  {
    type: 'link',
    name: 'Trabajos',
    link: 'trabajos',
    route: '/apps/consmare',
    icon: 'assignment',
    role: {
      ROLE_SADMIN: true,
      ROLE_ADMIN: true,
      ROLE_TECHNICAL: true,
      ROLE_CLIENT: true,
      ROLE_SUPERVISOR: true
    },
    permission: null
  },
  {
    type: 'separator',
    name: 'Inventario',
    link: '',
    route: '',
    icon: '',
    role: {
      ROLE_SADMIN: true,
      ROLE_ADMIN: true,
      ROLE_TECHNICAL: true,
      ROLE_CLIENT: false,
      ROLE_SUPERVISOR: false
    },
    permission: null
  },
  {
    type: 'link',
    name: 'Categorias',
    link: 'categorias',
    route: '/apps/consmare',
    icon: 'category',
    role: {
      ROLE_SADMIN: true,
      ROLE_ADMIN: true,
      ROLE_TECHNICAL: false,
      ROLE_CLIENT: false,
      ROLE_SUPERVISOR: false
    },
    permission: null
  },
  {
    type: 'link',
    name: 'Elementos',
    link: 'elementos',
    route: '/apps/consmare',
    icon: 'kitchen',
    role: {
      ROLE_SADMIN: true,
      ROLE_ADMIN: true,
      ROLE_TECHNICAL: true,
      ROLE_CLIENT: false,
      ROLE_SUPERVISOR: false
    },
    permission: null
  },
  {
    type: 'separator',
    name: 'Datos',
    link: '',
    route: '',
    icon: '',
    role: {
      ROLE_SADMIN: true,
      ROLE_ADMIN: false,
      ROLE_TECHNICAL: false,
      ROLE_CLIENT: false,
      ROLE_SUPERVISOR: false
    },
    permission: null
  },
  {
    type: 'link',
    name: 'Resumen',
    link: 'resumen',
    route: '/apps/consmare',
    icon: 'analytics',
    role: {
      ROLE_SADMIN: true,
      ROLE_ADMIN: false,
      ROLE_TECHNICAL: false,
      ROLE_CLIENT: false,
      ROLE_SUPERVISOR: false
    },
    permission: null
  },
  {
    type: 'link',
    name: 'Dashboard',
    link: 'dashboard',
    route: '/apps/consmare',
    icon: 'bar_chart',
    role: {
      ROLE_SADMIN: true,
      ROLE_ADMIN: true,
      ROLE_TECHNICAL: false,
      ROLE_CLIENT: false,
      ROLE_SUPERVISOR: false
    },
    permission: {
      dashboardWorks: true
    }
  }
];
