// Productos publicitarios
export const spaceAdsItems = [
  {
    type: 'link',
    name: 'Oportunidades',
    link: 'oportunidades',
    route: '/apps/espacios-publicitarios',
    icon: 'paid',
    role: {
      ROLE_SADMIN: true,
      ROLE_ADMIN: true,
      ROLE_TECHNICAL: true,
      ROLE_CLIENT: false,
      ROLE_SUPERVISOR: false
    },
    permission:  {
      spacesAds: true
    }
  },
  {
    type: 'link',
    name: 'Presupuestos',
    link: 'presupuestos',
    route: '/apps/espacios-publicitarios',
    icon: 'assignment',
    role: {
      ROLE_SADMIN: true,
      ROLE_ADMIN: true,
      ROLE_TECHNICAL: true,
      ROLE_CLIENT: false,
      ROLE_SUPERVISOR: false
    },
    permission:  {
      spacesAds: true
    }
  },
  {
    type: 'link',
    name: 'Proyectos',
    link: 'proyectos',
    route: '/apps/espacios-publicitarios',
    icon: 'pending_actions',
    role: {
      ROLE_SADMIN: true,
      ROLE_ADMIN: true,
      ROLE_TECHNICAL: true,
      ROLE_CLIENT: false,
      ROLE_SUPERVISOR: false
    },
    permission:  {
      project: true
    }
  },
  {
    type: 'link',
    name: 'Productos',
    link: 'productos',
    route: '/apps/espacios-publicitarios',
    icon: 'inventory',
    role: {
      ROLE_SADMIN: true,
      ROLE_ADMIN: true,
      ROLE_TECHNICAL: true,
      ROLE_CLIENT: false,
      ROLE_SUPERVISOR: false
    },
    permission:  {
      spacesAds: true
    }
  },
  {
    type: 'link',
    name: 'Dashboard',
    link: 'dashboard',
    route: '/apps/espacios-publicitarios',
    icon: 'dashboard',
    role: {
      ROLE_SADMIN: true,
      ROLE_ADMIN: true,
      ROLE_TECHNICAL: false,
      ROLE_CLIENT: false,
      ROLE_SUPERVISOR: false
    },
    permission:  {
      spacesAds: true
    }
  }
];
