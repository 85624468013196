// Documentacion Seguridad Alimentaria
export const docsFoodSafetyItems = [
  {
    type: 'link',
    name: 'Tiendas',
    link: 'tiendas',
    route: '/apps/seguridad-alimentaria/documentacion',
    icon: 'list_alt',
    role: {
      ROLE_SADMIN: true,
      ROLE_ADMIN: true,
      ROLE_TECHNICAL: true,
      ROLE_CLIENT: true,
      ROLE_SUPERVISOR: true
    },
    permission: {
      permission: null
    }
  },
  {
    type: 'link',
    name: 'Supervisores',
    link: 'supervisores',
    route: '/apps/seguridad-alimentaria/documentacion',
    icon: 'category',
    role: {
      ROLE_SADMIN: true,
      ROLE_ADMIN: true,
      ROLE_TECHNICAL: true,
      ROLE_CLIENT: true,
      ROLE_SUPERVISOR: true
    },
    permission: {
      permission: null
    }
  },
  {
    type: 'link',
    name: 'franquiciados',
    link: 'atributos-productos',
    route: '/apps/seguridad-alimentaria/documentacion',
    icon: 'list',
    role: {
      ROLE_SADMIN: true,
      ROLE_ADMIN: true,
      ROLE_TECHNICAL: true,
      ROLE_CLIENT: true,
      ROLE_SUPERVISOR: true
    },
    permission: {
      permission: null
    }
  },
];
