// Administracion CRUDS
export const adminItems = [
  {
    type: 'link',
    name: 'Empresas',
    link: 'empresas',
    route: '/admin',
    icon: 'business',
    role: {
      ROLE_SADMIN: true,
      ROLE_ADMIN: false,
      ROLE_TECHNICAL: false,
      ROLE_CLIENT: false,
      ROLE_SUPERVISOR: false
    },
    permission: null
  },
  {
    type: 'link',
    name: 'Clientes',
    link: 'clientes',
    route: '/admin',
    icon: 'people',
    role: {
      ROLE_SADMIN: true,
      ROLE_ADMIN: true,
      ROLE_TECHNICAL: false,
      ROLE_CLIENT: false,
      ROLE_SUPERVISOR: false
    },
    permission: null
  },
  {
    type: 'link',
    name: 'Departamentos',
    link: 'departamentos',
    route: '/admin',
    icon: 'view_quilt',
    role: {
      ROLE_SADMIN: true,
      ROLE_ADMIN: false,
      ROLE_TECHNICAL: false,
      ROLE_CLIENT: false,
      ROLE_SUPERVISOR: false
    },
    permission: null
  },
  {
    type: 'link',
    name: 'Empleados',
    link: 'empleados',
    route: '/admin',
    icon: 'people',
    role: {
      ROLE_SADMIN: true,
      ROLE_ADMIN: true,
      ROLE_TECHNICAL: false,
      ROLE_CLIENT: false,
      ROLE_SUPERVISOR: false
    },
    permission: null
  },
  {
    type: 'link',
    name: 'Utilidades',
    link: 'utilidades',
    route: '/admin',
    icon: 'construction',
    role: {
      ROLE_SADMIN: true,
      ROLE_ADMIN: true,
      ROLE_TECHNICAL: false,
      ROLE_CLIENT: false,
      ROLE_SUPERVISOR: false
    },
    permission: null
  },
  {
    type: 'link',
    name: 'Configuraciones',
    link: 'config',
    route: '/admin',
    icon: 'settings',
    role: {
      ROLE_SADMIN: true,
      ROLE_ADMIN: true,
      ROLE_TECHNICAL: false,
      ROLE_CLIENT: false,
      ROLE_SUPERVISOR: false
    },
    permission: null
  }
];
