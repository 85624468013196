// Fichas
export const dataSheetsItems = [
  {
    type: 'separator',
    name: 'Productos',
    link: '',
    route: '',
    icon: '',
    role: {
      ROLE_SADMIN: true,
      ROLE_ADMIN: true,
      ROLE_TECHNICAL: true,
      ROLE_CLIENT: false,
      ROLE_SUPERVISOR: false
    },
    permission: {
      dataSheet: true
    }
  },
  {
    type: 'link',
    name: 'Productos',
    link: 'productos',
    route: '/apps/seguridad-alimentaria/fichas',
    icon: 'list_alt',
    role: {
      ROLE_SADMIN: true,
      ROLE_ADMIN: false,
      ROLE_TECHNICAL: false,
      ROLE_CLIENT: false,
      ROLE_SUPERVISOR: false
    },
    permission: {
      products: true
    }
  },
  {
    type: 'link',
    name: 'Categorias',
    link: 'categorias-atributos',
    route: '/apps/seguridad-alimentaria/fichas',
    icon: 'category',
    role: {
      ROLE_SADMIN: true,
      ROLE_ADMIN: true,
      ROLE_TECHNICAL: true,
      ROLE_CLIENT: false,
      ROLE_SUPERVISOR: false
    },
    permission: {
      dataSheet: true
    }
  },
  {
    type: 'link',
    name: 'Atributos',
    link: 'atributos-productos',
    route: '/apps/seguridad-alimentaria/fichas',
    icon: 'list',
    role: {
      ROLE_SADMIN: true,
      ROLE_ADMIN: true,
      ROLE_TECHNICAL: true,
      ROLE_CLIENT: false,
      ROLE_SUPERVISOR: false
    },
    permission: {
      dataSheet: true
    }
  },
  {
    type: 'separator',
    name: 'Fichas',
    link: '',
    route: '',
    icon: '',
    role: {
      ROLE_SADMIN: true,
      ROLE_ADMIN: true,
      ROLE_TECHNICAL: true,
      ROLE_CLIENT: true,
      ROLE_SUPERVISOR: true
    },
    permission: null
  },
  {
    type: 'link',
    name: 'Enriquecidas',
    link: 'enriquecidas',
    route: '/apps/seguridad-alimentaria/fichas',
    icon: 'reorder',
    role: {
      ROLE_SADMIN: true,
      ROLE_ADMIN: true,
      ROLE_TECHNICAL: true,
      ROLE_CLIENT: true,
      ROLE_SUPERVISOR: true
    },
    permission: null
  },
  {
    type: 'link',
    name: 'Técnicas',
    link: 'tecnicas',
    route: '/apps/seguridad-alimentaria/fichas',
    icon: 'list_alt',
    role: {
      ROLE_SADMIN: true,
      ROLE_ADMIN: true,
      ROLE_TECHNICAL: true,
      ROLE_CLIENT: true,
      ROLE_SUPERVISOR: true
    },
    permission: null
  },
  {
    type: 'link',
    name: 'Fds',
    link: 'fds',
    route: '/apps/seguridad-alimentaria/fichas',
    icon: 'warning',
    role: {
      ROLE_SADMIN: true,
      ROLE_ADMIN: true,
      ROLE_TECHNICAL: true,
      ROLE_CLIENT: true,
      ROLE_SUPERVISOR: true
    },
    permission: null
  }
];
